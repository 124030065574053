<template>
  <div class="page__banner-add">
    <y-form
      style="width: 100%"
      label-width="100px"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitText: '确定',
        resetText: '取消'
      }"
      @submit="submit"
      @reset="reset"
    ></y-form>
  </div>
</template>

<script>
import { uid } from 'uid'
import Upload from '@/components/upload'

export default {
  name: 'BannerAdd',
  data() {
    return {
      model: {
        model: undefined,
        title: '',
        urlType: '',
        file: null
      },
      fields: [
        {
          type: 'radio-group',
          formItemProps: {
            style: 'width: 460px',
            label: '模块',
            prop: 'model'
          },
          attributes: {
            options: [
              {
                value: 1,
                label: '首页'
              },
              {
                value: 2,
                label: '校地合作'
              },
              {
                value: 3,
                label: '校企合作'
              }
            ].map((item) => ({
              label: item.value,
              render(h) {
                return item.label
              }
            }))
          }
        },
        {
          type: 'radio-group',
          formItemProps: {
            style: 'width: 460px',
            label: 'tab',
            prop: 'tab'
          },
          attributes: {
            options: [
              {
                value: 'banner',
                label: 'banner'
              },
              {
                value: 'card',
                label: 'card'
              }
            ].map((item) => ({
              label: item.value,
              render(h) {
                return item.label
              }
            }))
          }
        },
        {
          type: 'input',
          formItemProps: {
            style: 'width: 460px',
            label: '标题',
            prop: 'title'
          }
        },
        {
          formItemProps: {
            style: 'width: 460px',
            label: '图片',
            prop: 'file'
          },
          render(h, prop, field, model) {
            return <Upload v-model={model[prop]}></Upload>
          }
        },
        // {
        //   type: 'radio-group',
        //   formItemProps: {
        //     style: 'width: 460px',
        //     label: '跳转类型',
        //     prop: 'urlType'
        //   },
        //   attributes: {
        //     options: [
        //       {
        //         value: 1,
        //         label: '公众号链接'
        //       },
        //       {
        //         value: 2,
        //         label: '内部文章'
        //       },
        //     ].map((item) => ({
        //       label: item.value,
        //       render(h) {
        //         return item.label
        //       }
        //     }))
        //   }
        // },
        {
          type: 'input',
          formItemProps: {
            style: 'width: 460px',
            label: '跳转地址',
            prop: 'url'
          }
        }
      ],
      rules: {
        model: [{ required: true, message: '请选择模块', trigger: 'blur' }],
        tab: [{ required: true, message: '请选择tab', trigger: 'change' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        file: [{ required: true, message: '请上传图片', trigger: 'change' }],
        urlType: [
          { required: true, message: '请选择跳转类型', trigger: 'change' }
        ],
        url: [{ required: true, message: '请输入地址', trigger: 'blur' }]
      }
    }
  },
  computed: {
    bannerId() {
      return this.$route.params.id
    }
  },
  watch: {
    bannerId: {
      handler(val) {
        if (val) this.fetchInfo()
      },
      immediate: true
    }
  },
  methods: {
    async fetchInfo() {
      const res = await this.$serve.mpBannerInfo({
        aimid: this.bannerId
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.model = {
        model: +res.model,
        tab: res.tab,
        title: res.title,
        file: {
          uid: uid(),
          status: 'success',
          url: res.imgUrl
        },
        urlType: res.urlType + '',
        url: res.url
      }
    },
    async submit({ file, ...data }) {
      if (file?.response?.data || file?.url) {
        data.imgUrl = file.response?.data || file?.url
      }
      if (this.bannerId) {
        data.id = this.bannerId
        this.update(data)
      } else {
        this.add(data)
      }
    },
    async add(data) {
      const res = await this.$serve.mpBannerAdd({
        data
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('Banner添加成功')
      this.$router.push('/mini-banners')
    },
    async update(data) {
      const res = await this.$serve.mpBannerUpdate({
        data
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('Banner修改成功')
      this.$router.push('/mini-banners')
    },
    reset() {
      this.$router.push('/mini-banners')
    }
  }
}
</script>

<style lang="scss" scoped>
.page__banner-add {
  ::v-deep .upload .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
}
</style>
